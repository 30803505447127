<template>
  <main>
    <trac-loading v-if="isLoading"></trac-loading>
    <div v-else>
      <div class="mb-6">
        <h2 class="font-semibold text-blue-900 text-2xl">Savings Waitlist</h2>
      </div>
      <div class="flex flex-row mt-5">
        <div class="float-right">
          <input
            type="text"
            class="inline-block px-2 ring-2 outline-none ring-blue-500 rounded-sm ml-2 w-64 text-sm py-2"
            name=""
            placeholder="Search transaction referrence"
            id=""
            v-model="search"
          />
        </div>
      </div>
      <!-- <div class="flex my-3">
        <div class="">
          <button
            class="bg-gray-800 py-3 px-3 rounded-md text-white"
            @click="
              $router.push({
                name: 'WalletTransactionLog',
              })
            "
          >
            &laquo; Back
          </button>
        </div>
      </div>
      <div class="bg-blue-100 p-5 my-5">
        <p>
          Please note that all downloads are in .txt formats. You will need to
          change it to .csv format after downloading it. Merci.
        </p>
      </div>

      <div class="flex justify-end items-end">
        <button
          class="bg-blue-600 py-3 px-6 rounded-md text-white"
          @click="downloadCSV"
        >
          Download CSV
        </button>
      </div>
 
      <div class="flex flex-col">
        <div
          class="flex justify-between items-center m-3 flex-grow-0 flex-shrink"
        >
          <div class="">
            <div class="flex time-filter">
              <span class="mr-4">From: </span>
              <input
                type="date"
                class="inline-block border-2 px-2 rounded-sm mr-2"
                name=""
                id=""
                v-model="dateData.start_date"
              />
              <input
                type="time"
                class="inline-block border-2 px-2 rounded-sm mr-2"
                name=""
                id=""
                v-model="dateData.startTime"
              />
            </div>
            <div class="flex time-filter mt-2">
              <span class="mr-9">To: </span>
              <input
                type="date"
                class="inline-block border-2 px-2 rounded-sm mr-2"
                name=""
                id=""
                v-model="dateData.end_date"
              />
              <input
                type="time"
                class="inline-block border-2 px-2 rounded-sm mr-2"
                name=""
                id=""
                v-model="dateData.endTime"
              />
            </div>
            <div class="flex mt-3">
              <button
                @click="fetchRecords"
                class="block mr-2 uppercase shadow bg-blue-800 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-2 px-8 rounded"
              >
                Get Records
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="flex flex-row mt-5 overflow-auto justify-end">
        <div
          class="border-blue-500 border-radius rounded-md text-right d-block border-2 px-6 py-3"
        >
          Balance:
          <span class="font-normal text-blue-800"
            >₦{{ totalWalletBalance }}</span
          >
        </div>
      </div> -->
      <div class="flex flex-row mt-5 overflow-auto" style="height: 800px">
        <table class="shadow-lg bg-white w-full">
          <thead>
            <th
              class="text-l p-3 text-left"
              colspan="5"
              style="background: #dee6f2"
            >
              Merchant Information
            </th>
            <tr style="background: #fff">
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Business ID
              </th>
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Business Name
              </th>
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Email Address
              </th>
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Phone No
              </th>
              <th class="text-xs border whitespace-nowrap text-left px-8 py-2">
                Created Date
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(account, i) in filterDocuments" :key="i">
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.business_id }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.business_name }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.email }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{ account.phone_number }}
              </td>
              <td class="border px-8 py-3 whitespace-nowrap text-xs">
                {{
                  moment(account.created_at).format('DD MMM YYYY - h:mm:ss a')
                }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <div class="mt-3">
        <button
          @click="prevPage(currentPage)"
          :disabled="currentPage === 1 ? isDisabled : !isDisabled"
          class="uppercase shadow ml-3 hover:bg-blue-600 focus:shadow-outline focus:outline-none text-xs font-bold py-3 px-8 rounded"
          :class="
            currentPage === 1
              ? 'bg-blue-50 text-black'
              : 'bg-blue-900 text-white'
          "
        >
          &laquo; Prev
        </button>
        <button
          @click="nextPage(currentPage)"
          class="float-right uppercase shadow ml-3 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
        >
          Next &raquo;
        </button>
      </div>
      <div
        v-if="alertDiv"
        class="modal fixed bg-opacity-50 bg-gray-900 flex justify-center items-center w-screen h-screen top-0 left-0 z-40"
      >
        <div
          class="w-1/4 rounded-lg bg-white p-5 grid grid-cols-1 gap-4 text-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            fill="currentColor"
            class="bi bi-exclamation-triangle-fill text-green-500 mx-auto"
            viewBox="0 0 16 16"
          >
            <path
              d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
            />
          </svg>
          Please check your email in 5 mins for the download link.
          <br />Please note that it will take longer to generate a download link
          for larger datasets.
          <button
            @click="alertDiv = false"
            class="float-right uppercase shadow mx-auto hover:bg-red-600 bg-red-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-2 rounded"
            style="width: inherit"
          >
            Close
          </button>
        </div>
      </div> -->
    </div>
  </main>
</template>

<script>
import moment from 'moment';
import { json2excel } from 'js2excel';
import { mapGetters } from 'vuex';

export default {
  data() {
    return {
      moment,
      isLoading: false,
      waitList: [],
      search: '',
    };
  },
  async created() {
    await this.fetchRecords();
  },
  computed: {
    ...mapGetters(['GET_SINGLE_WALLET_TRANSACTION']),
    filterDocuments() {
      return this.waitList.filter((document) => {
        return document.email.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  methods: {
    async fetchRecords() {
      try {
        this.isLoading = true;
        let res = await this.$store.dispatch('GET_ALL_WAITLIST');
        if (res.status) {
          this.waitList = res.data.item;
          this.isLoading = false;
        }
      } catch (error) {
        this.isLoading = false;
      }
    },

    async searchByReferrence() {
      this.isLoading = true;
      let res = await this.$store.dispatch(
        'FETCH_SEARCH_BY_TRANSACTION_REFERENCE',
        {
          transactionReference: this.search,
        }
      );
      if (res.status) {
        this.isLoading = false;
      }
      this.isLoading = false;
    },
  },
};
</script>
<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
  position: sticky;
  top: 0;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
